var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "mt-2 container-max-width", attrs: { id: "renewals-page" } },
    [
      _c("masthead", {
        attrs: { title: "State Registrations", "show-search": false },
      }),
      _c(
        "b-row",
        [
          _c("b-col", [
            _c("div", { staticClass: "mb-4" }, [
              _vm._v(
                "\n        The Registrations tab helps you keep track of state compliance or renewals for companies in your account.\n        Clicking on a company will bring up an option to hire us for the compliance filing, or you can choose “Do it Yourself Filing”, and be directed to our free forms library, which lists available options for you to file yourself with the state.\n      "
              ),
            ]),
            _c(
              "a",
              {
                staticClass: "link-underline link-color",
                on: {
                  click: function ($event) {
                    return _vm.logFaqCategoryNavigation(
                      _vm.faqCategoryId,
                      _vm.faqInteraction
                    )
                  },
                },
              },
              [
                _vm._v(
                  "\n        Registrations | Frequently Asked Questions\n        "
                ),
                _c("arrow-right", { staticClass: "arrow-icon" }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c("masthead", {
                attrs: { "filter-options": _vm.filterOptions },
                on: { changed: _vm.searchChanged },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _vm.isCustomEventButtonImplemented
                ? _c(
                    "b-button",
                    {
                      class: _vm.$mq !== "sm" ? "btn-shadow mr-2 rounded" : "",
                      attrs: { variant: "primary" },
                      on: { click: _vm.openAddEventModal },
                    },
                    [
                      _c("feather-icon", { attrs: { type: "tag" } }),
                      _vm._v("\n        Add custom event\n      "),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _vm.defaultFilterApplied
                ? _c(
                    "b-tabs",
                    {
                      model: {
                        value: _vm.tabIndex,
                        callback: function ($$v) {
                          _vm.tabIndex = $$v
                        },
                        expression: "tabIndex",
                      },
                    },
                    [
                      _c(
                        "b-tab",
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v(
                              "\n            State Registrations\n          "
                            ),
                          ]),
                          _c("ajax-table", {
                            ref: "accountRenewalsTable",
                            attrs: {
                              busy: _vm.isBusy,
                              "table-definition": _vm.myComplianceEvents,
                              "tbody-tr-class": _vm.complianceEventRowClass,
                            },
                            on: {
                              "update:busy": function ($event) {
                                _vm.isBusy = $event
                              },
                              loaded: _vm.onLoad,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "cell(actions)",
                                  fn: function (row) {
                                    return [
                                      _vm.verifyRenewalAction(row.item)
                                        ? _c("div", [
                                            _c("p", [
                                              _vm._v("no action needed"),
                                            ]),
                                          ])
                                        : _c(
                                            "div",
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass: "primary mr-4",
                                                  attrs: {
                                                    "aria-label":
                                                      "Hire Us Filing Button",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.hireUsFilingOption(
                                                        row.item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  Hire Us\n                "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "b-button",
                                                {
                                                  staticClass: "link",
                                                  attrs: { variant: "link" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.diyFilingOption(
                                                        row.item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  Do it Yourself\n                "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3025905152
                            ),
                          }),
                        ],
                        2
                      ),
                      _c(
                        "b-tab",
                        {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "cell(actions)",
                                fn: function (row) {
                                  return [
                                    _vm.verifyRenewalAction(row.item)
                                      ? _c("div", [
                                          _c("p", [_vm._v("no action needed")]),
                                        ])
                                      : _c(
                                          "div",
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass: "primary mr-4",
                                                attrs: {
                                                  "aria-label":
                                                    "Hire Us Filing Button",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.hireUsFilingOption(
                                                      row.item
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                Hire Us\n              "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-button",
                                              {
                                                staticClass: "link",
                                                attrs: { variant: "link" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.diyFilingOption(
                                                      row.item
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                Do it Yourself\n              "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1006345344
                          ),
                        },
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v(
                              "\n            State Registrations (Shared)\n          "
                            ),
                          ]),
                          _c("ajax-table", {
                            ref: "sharedRenewalsTable",
                            attrs: {
                              "table-definition": _vm.sharedComplianceEvents,
                              "tbody-tr-class": _vm.complianceEventRowClass,
                            },
                            on: { loaded: _vm.onSharedLoad },
                          }),
                        ],
                        2
                      ),
                      _c(
                        "b-tab",
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v(
                              "\n            Sub Registrations\n          "
                            ),
                          ]),
                          _c("ajax-table", {
                            ref: "subRegistrationsTable",
                            attrs: {
                              "table-definition": _vm.mySubRegistrations,
                            },
                            on: { loaded: _vm.onSubRegistrationLoad },
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("add-event-modal", {
        attrs: { visible: _vm.showAddEventModal, loaded: _vm.loaded },
        on: {
          hidden: function ($event) {
            _vm.showAddEventModal = false
          },
        },
      }),
      _c("payment-method-selection-modal", {
        ref: "paymentMethodSelectionModal",
        attrs: {
          visible: _vm.showPaymentMethodSelectionModal,
          "selected-compliance-event": _vm.selectedComplianceEvent,
          loaded: _vm.loaded,
        },
        on: {
          "payment-method-selected":
            _vm.updateSelectedPaymentMethodAndAddComplianceService,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }