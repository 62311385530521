<template>
  <b-container id="renewals-page" class="mt-2 container-max-width">
    <masthead title="State Registrations" :show-search="false" />
    <b-row>
      <b-col>
        <div class="mb-4">
          The Registrations tab helps you keep track of state compliance or renewals for companies in your account.
          Clicking on a company will bring up an option to hire us for the compliance filing, or you can choose “Do it Yourself Filing”, and be directed to our free forms library, which lists available options for you to file yourself with the state.
        </div>
        <a class="link-underline link-color"
           @click="logFaqCategoryNavigation(faqCategoryId, faqInteraction)">
          Registrations | Frequently Asked Questions
          <arrow-right class="arrow-icon" />
        </a>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <masthead :filter-options="filterOptions" @changed="searchChanged" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-button
          v-if="isCustomEventButtonImplemented"
          variant="primary"
          :class="$mq !== 'sm' ? 'btn-shadow mr-2 rounded' : ''"
          @click="openAddEventModal"
        >
          <feather-icon type="tag" />
          Add custom event
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-tabs v-if="defaultFilterApplied" v-model="tabIndex">
          <b-tab>
            <template slot="title">
              State Registrations
            </template>
            <ajax-table
              ref="accountRenewalsTable"
              :busy.sync="isBusy"
              :table-definition="myComplianceEvents"
              :tbody-tr-class="complianceEventRowClass"
              @loaded="onLoad"
            >
              <template slot="cell(actions)" slot-scope="row">
                <div v-if="verifyRenewalAction(row.item)">
                  <p>no action needed</p>
                </div>
                <div v-else>
                  <button
                    class="primary mr-4"
                    aria-label="Hire Us Filing Button"
                    @click="hireUsFilingOption(row.item)"
                  >
                    Hire Us
                  </button>
                  <b-button
                    variant="link"
                    class="link"
                    @click="diyFilingOption(row.item)"
                  >
                    Do it Yourself
                  </b-button>
                </div>
              </template>
            </ajax-table>
          </b-tab>

          <b-tab>
            <template slot="title">
              State Registrations (Shared)
            </template>
            <ajax-table
              ref="sharedRenewalsTable"
              :table-definition="sharedComplianceEvents"
              :tbody-tr-class="complianceEventRowClass"
              @loaded="onSharedLoad"
            />
            <template slot="cell(actions)" slot-scope="row">
              <div v-if="verifyRenewalAction(row.item)">
                <p>no action needed</p>
              </div>
              <div v-else>
                <button
                  class="primary mr-4"
                  aria-label="Hire Us Filing Button"
                  @click="hireUsFilingOption(row.item)"
                >
                  Hire Us
                </button>
                <b-button
                  variant="link"
                  class="link"
                  @click="diyFilingOption(row.item)"
                >
                  Do it Yourself
                </b-button>
              </div>
            </template>
          </b-tab>
          <b-tab>
            <template slot="title">
              Sub Registrations
            </template>
            <ajax-table
              ref="subRegistrationsTable"
              :table-definition="mySubRegistrations"
              @loaded="onSubRegistrationLoad"
            />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>

    <add-event-modal
      :visible="showAddEventModal"
      :loaded="loaded"
      @hidden="showAddEventModal = false"
    />

    <payment-method-selection-modal
      ref="paymentMethodSelectionModal"
      :visible="showPaymentMethodSelectionModal"
      :selected-compliance-event="selectedComplianceEvent"
      :loaded="loaded"
      @payment-method-selected="updateSelectedPaymentMethodAndAddComplianceService"
    />
  </b-container>
</template>

<script>
import { usStates } from '@/common/modules/usStates'
import _ from 'lodash'
import http from '../http'
import { getDateFilter } from '@/common/modules/dates'
import { setTimeout } from 'timers'
import { faqMixin } from '@/mixins/faqMixin'
import { yearsSinceDue } from '@/helpers'

const { DateTime } = require('luxon')

export default {
  name: 'Registrations',
  mixins: [faqMixin],
  components: {
    ArrowRight: () => import('@images/ui/arrow-right-2FB2AE.svg'),
    AddEventModal: () => import('../components/AddEventModal'),
    AjaxTable: () => import('../components/shared/AjaxTable'),
    FeatherIcon: () => import('@/components/shared/FeatherIcon'),
    Masthead: () => import('@/components/shared/Masthead'),
    PaymentMethodSelectionModal: () => import('../components/PaymentMethodSelectionModal'),
  },
  data() {
    return {
      faqCategoryId: null,
      faqInteraction: { type: 'link', action: 'nav-from-page', categoryName: 'Registrations Tab', timestamp: Date.now() },
      isCustomEventButtonImplemented: false,
      loaded: false,
      dataCount: null,
      dataSharedCount: null,
      tabIndex: 0,
      query: null,
      appliedFilters: [],
      showAddEventModal: false,
      showFilingOptionsModal: false,
      showPaymentMethodSelectionModal: false,
      selectedComplianceEvent: null,
      filterOptions: {
        dateRadioGroup: {
          filters: [
            getDateFilter('UPCOMING_ALL'),
            getDateFilter('UPCOMING_12_MONTHS'),
            getDateFilter('UPCOMING_6_MONTHS'),
            getDateFilter('UPCOMING_1_MONTH'),
            getDateFilter('PAST_1_MONTH'),
            getDateFilter('PAST_6_MONTHS'),
            getDateFilter('PAST_12_MONTHS'),
            getDateFilter('PAST_ALL'),
          ],
          scope: {
            startField: 'due_date_gte',
            endField: 'due_date_lte',
            formatter: (time) => DateTime.fromSeconds(time).toFormat('yyyy-MM-dd'),
          },
          selection: 'UPCOMING_12_MONTHS',
        },
      },
      defaultFilterApplied: false,
      isBusy: true,
    }
  },
  computed: {
    usStates() {
      return usStates
    },
    myComplianceEvents() {
      return {
        url: 'client/compliance_events',
        columns: [
          { key: 'company_name', label: 'Company Name', sortable: true },
          { key: 'jurisdiction', label: 'Jurisdiction', sortable: true },
          { key: 'rule_description', label: 'Type of Filing', sortable: true },
          { key: 'due_date', label: 'Due Date', sortable: true },
          { key: 'actions', label: 'Renewal Filing' },
        ],
        parameters: {
          search_name: 'search_compliance_page',
          query: this.query,
          filter: this.appliedFilters,
        },
        defaultOrderDirection: 'asc',
        defaultOrderBy: 'due_date',
      }
    },
    sharedComplianceEvents() {
      return {
        url: 'client/compliance_events/shared',
        columns: [
          { key: 'company_name', label: 'Company Name', sortable: true },
          { key: 'jurisdiction', label: 'Jurisdiction', sortable: true },
          { key: 'rule_description', label: 'Type of Filing', sortable: true },
          { key: 'due_date', label: 'Due Date', sortable: true },
          { key: 'actions', label: 'Renewal Filing' },
        ],
        parameters: {
          search_name: 'search_compliance_page',
          query: this.query,
          filter: this.appliedFilters,
        },
        defaultOrderDirection: 'desc',
        defaultOrderBy: 'due_date',
      }
    },
    mySubRegistrations() {
      return {
        url: 'client/sub_registrations',
        columns: [
          { key: 'company_name', label: 'Company Name', sortable: true },
          { key: 'name', label: 'Sub Registration Name', sortable: true },
          { key: 'type', label: 'Type' },
          { key: 'jurisdiction.state_province_region', label: 'Jurisdiction', sortable: true },
          { key: 'local_jurisdictions_name', label: 'Local Jurisdiction', sortable: true },
        ],
        parameters: {
          query: this.query,
        },
        defaultOrderDirection: 'asc',
        defaultOrderBy: 'jurisdiction.state_province_region',
      }
    },
  },
  async mounted() {
    if (this.faqInformation.length === 0) {
      await this.fetchFaqInformation()
    }
    this.faqCategoryId = this.faqInformation.find(item => item.name === "Registrations Tab")?.id

    while(this.dataCount === null || this.dataSharedCount === null) {
      await new Promise(r => setTimeout(r, 200))
    }
    this.tabIndex = this.dataCount === 0 && this.dataSharedCount > 0 ? 1:0

    await this.directLinkToItemIfAvailable()
  },
  methods: {
    complianceEventRowClass(item, type) {
      let rowStatus = ''
      if (item && type === 'row' && this.preventOrder(item)) {
        rowStatus = 'disabled'
      // the item with no jurisdiction is Federal
      } else if (item && type === 'row' && item.jurisdiction === '') {
        rowStatus = 'hidden'
      }

      return rowStatus
    },
    updateFormat(tableData) {
      tableData.map(record => {
        record['jurisdiction'] = this.abbreviationFor(record.jurisdiction_id)
        record['rule_description'] =
          record.rule_type === 'ongoing' ? 'Ongoing Registrations' : 'Initial Report'

        return record
      })

      this.loaded = true
    },
    abbreviationFor(jurisdictionId) {
      const jurisdiction = this.usStates.filter(j => j.id === jurisdictionId)[0]
      return jurisdiction ? jurisdiction.stateProvinceRegion : ''
    },
    searchChanged(searchObject) {
      this.query = searchObject.query
      this.appliedFilters = searchObject.filters
      // We defaulted to a filter selection, so wait till that propagates here before allowing the
      // ajax tables to load
      this.defaultFilterApplied = true
    },
    openAddEventModal() {
      this.showAddEventModal = true
    },
    addCostAndFees(data, item) {
      item.__cost = data.price
      item.__stateFees = data.filing_methods.sort((a, b) => a.type.localeCompare(b.type))[0].cost

      if (this.eventIsLate(item) && item.late_fee) item.__cost += item.late_fee
      if (this.eventIsMoreThanAYearOld(item)) item.__isBeyondSaving = true

      return item
    },
    async diyFilingOption(item) {
      await this.$router.push({
        name: 'filingMethodSelection',
        params: {
          companyId: item.company_id,
          jurisdictionId: item.jurisdiction_id,
          filingId: item.filing_id,
        },
      })
    },
    async hireUsFilingOption(item) {
      if (this.preventOrder(item)) return

      const response = await http.get(`filings/${item.filing_id}/purchasable_website_filing_products`, {
        params: {
          company_id: item.company_id,
        },
      })

      if (response.data.success) {
        const adjustedItem = this.addCostAndFees(response.data.result, item)
        await this.openPaymentMethodSelectionModal(adjustedItem)
      } else {
        this.$bvToast.toast(
          `Unable to locate product: ${response.data.error.message}`,
          {
            title: 'Error',
            variant: 'danger',
            solid: true,
            noAutoHide: true,
          }
        )
      }
    },
    async openPaymentMethodSelectionModal(item) {
      this.selectedComplianceEvent = item

      this.showPaymentMethodSelectionModal = true
      this.$refs.paymentMethodSelectionModal.isVisible = true
    },
    async directLinkToItemIfAvailable() {
      const companyId = this.$route.query.company_id

      if (companyId) {
        const complianceEvent = this.$refs.accountRenewalsTable?.records.find(registration => registration.company_id === companyId)

        if (complianceEvent && !this.preventOrder(complianceEvent)) {
          this.isBusy = true
          await this.hireUsFilingOption(complianceEvent)
          this.isBusy = false
        }
      }
    },
    async updateSelectedPaymentMethodAndAddComplianceService(payableId) {
      await this.addComplianceService(payableId)
    },
    async addComplianceService(payableId) {
      const params = {
        companyId: this.selectedComplianceEvent.company_id,
        registrationId: this.selectedComplianceEvent.registration_id,
        autopayPayableId: payableId,
      }

      if (this.mustGenerateImmediately(this.selectedComplianceEvent)) {
        params.generate_filing_immediately = true
        params.compliance_event_id = this.selectedComplianceEvent.id
      }

      const response = await this.$store.dispatch('services/addComplianceService', {
        params,
      })

      if (response.success) {
        this.$bvToast.toast("State Renewal scheduled successfully. We'll handle the filing!", {
          title: 'Success',
          variant: 'success',
          solid: true,
        })
        this.showPaymentMethodSelectionModal = false
        this.reloadRenewalsTables()
      } else if (response.data.error?.message === 'not authorized') {
        this.$bvToast.toast(
          "We could not complete your purchase because the primary account owner is the only " +
          "person authorized to set up and make payments on this account.", {
          title: 'Purchase not complete',
          variant: 'warning',
          solid: true,
          autoHideDelay: 10000,
        })
      } else {
        this.$bvToast.toast('There was a problem communicating with the server.', {
          title: 'Error',
          variant: 'danger',
          autoHideDelay: 2000,
        })
      }
    },
    reloadRenewalsTables() {
      this.showFilingOptionsModal = false
      this.$refs.accountRenewalsTable.reload()
      this.$refs.sharedRenewalsTable.reload()
    },
    preventOrder(complianceEvent) {
      const registration = complianceEvent.registration
      return !!(registration && registration.has_compliance_service)
    },
    //            INITIAL REPORT   | ANNUAL REPORT
    // TOO OLD    don't file       | don't file
    // PAST-DUE   file + late fee  | file + late fee
    // UPCOMING   file             | don't file
    mustGenerateImmediately(complianceEvent) {
      const dueDate = new Date(complianceEvent.due_date)
      const currentDate = new Date()

      // (1000 milliseconds * seconds in an hour * 24 hours)
      return ((dueDate - currentDate)/(1000*3600*24) <= 60)
    },
    eventIsLate(complianceEvent) {
      return yearsSinceDue(complianceEvent) >= 0
    },
    eventIsMoreThanAYearOld(complianceEvent) {
      return yearsSinceDue(complianceEvent) >= 1
    },
    startCase: input => _.startCase(input),
    onLoad(tableData) {
      this.dataCount = tableData.length
      this.updateFormat(tableData)
      this.isBusy = false
    },
    onSharedLoad(tableData) {
      this.dataSharedCount = tableData.length
      this.updateFormat(tableData)
    },
    onSubRegistrationLoad(tableData) {
      tableData.map(record => {
        record['type'] =
          record.kind === 'dba' ? 'Doing Business As' : '---'
        record['local_jurisdictions_name'] =
          record.local_jurisdiction_id !== null ? record.local_jurisdiction.name : '---'
      })
    },
    verifyRenewalAction(item) {
      return this.complianceEventRowClass(item, 'row')
    },
  },
}
</script>

<style lang="scss" >
// Palette
$black1: #231F20;
//$gray1: #6C757D;
$gray1: #A7A7A7;
$teal1: $ct-ui-color-10;
$teal2: $ct-ui-color-26;
$ct-ui-table-row-disabled-color: #f2f2f2;

// Typography
// Typography: Font-Weights
$font-weight-400: $ct-ui-font-weight-4;
$font-weight-600: $ct-ui-font-weight-6;

// Typography: Mixin
@mixin typography($font-size: 1.0em, $font-weight: $font-weight-600, $color: $black1) {
  font-family: "Open Sans", "Roboto", sans-serif;
  font-size: $font-size;
  font-weight: $font-weight;
  color: $color;
}

// Buttons
// Buttons: Mixins
@mixin btn-hover($hover-color: $teal2, $color: white) {
  border-color: $hover-color;
  background: $hover-color;
  color: $color;
}

@mixin btn-core($padding, $hover-color) {
  @include typography($font-size: 0.75em, $color: white);
  width: fit-content;
  border: 0.0625em solid $teal1;
  border-radius: 0.3125em;
  padding: $padding;
  margin: 0;
  outline: 0;
  line-height: 1.1;

  &:hover {
    @include btn-hover();
  }
}

@mixin btn-default($primary-color, $hover-color, $padding: 0.5em 1.0em) {
  background: $primary-color;
  color: white;
  border-color: $primary-color;
  @include btn-core($padding, $hover-color);
}

// Buttons: Types
button.primary {
  @include btn-default(
    $primary-color: $teal1,
    $hover-color: $teal2
  );
}

button.link {
  @include typography(
    $font-size: 0.75em,
    $color: $teal1
  );

  &:hover {
    color: $teal2;
  }
}

// Component
#renewals-page {
  .link-color { color: #2FB2AE !important; }
  .link-underline { text-decoration: underline !important; }
  .link-color:hover {
    cursor: pointer;
  }

  .modal-title {
    margin-top: -1em;
    margin-bottom: 1em;
    text-align: center;
  }

  tr.disabled {
    background: $ct-ui-table-row-disabled-color;
    pointer-events: none;
  }

  tr.hidden {
    display: none;
  }

  p {
    margin: 0;
    padding: 0.25em 0;
    @include typography(
      $font-size: 0.75em,
      $font-weight: $font-weight-400,
      $color: $gray1
    );
  }
}
</style>
